<template>
  <b-overlay :show="isLoadingMonetizingConfig || isUpdatingMonetizingConfig" rounded class="">
    <template #overlay>
      <div class="text-center">
        <b-spinner/> <br> Cargando información de configuración
      </div>
    </template>
    <b-card>
      <div class="text-right mb-1">
        <b-button size="sm" variant="warning" @click="goToHome"> <b-icon-arrow-left /> Regresar</b-button>
      </div>
      <div v-if="currentConfig">
        <validation-observer v-slot="{ invalid }" ref="MonetizingConfigEdition">
          <b-form @submit.prevent="updateConfiguration">
            <b-row  class="justify-content-center">
              <b-col>
                <b-row>
                  <b-col sm="">
                    <validation-provider name="Hotel" rules="required">
                      <b-form-group slot-scope="{ valid, errors }" label="Hotel">
                        <b-form-select
                          :state="errors[0] ? false : valid ? true : null"
                          v-model="currentConfig.hotel"
                          :disabled="!canEditConfig"
                          @change="resetRoomsCategory"
                          :clearable="false"
                        >
                          <option :value="null" disabledd>Seleccione una opción</option>
                          <option v-for="h in hotels" :key="h.id" :value="h.id">{{ h.name }}</option>
                        </b-form-select>
                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col sm="">
                    <validation-provider name="Beneficio" rules="required">
                      <b-form-group slot-scope="{ valid, errors }" label="Beneficio">
                        <b-form-select
                          :state="errors[0] ? false : valid ? true : null"
                          v-model="currentConfig.benefit"
                          :disabled="!canEditConfig"
                          :clearable="false"
                        >
                          <option :value="null" disabledd>Seleccione una opción</option>
                          <option
                            v-for="benefitOption in benefitOptions"
                            :key="benefitOption.id"
                            :value="benefitOption.id"
                          > {{ benefitOption.name }}
                          </option>                  </b-form-select>
                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col md="" sm="">
                    <ValidationProvider name="Categoría de Habitación">
                      <b-form-group label="Categoría de Habitación" slot-scope="{ valid, errors }">
                        <v-select
                          v-model="currentConfig.roomsCategory"
                          :disabled="!canEditConfig"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          multiple
                          label="name"
                          :options="roomsConfig"
                          :state="errors[0] ? false : valid ? true : null"
                        />
                        <div class="text-danger" style="font-size: 0.857rem">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>

                </b-row>

                <b-row>
                  <b-col md="" sm="">
                    <ValidationProvider name="Desde" rules="required|min_value:1|max_value:99">
                      <b-form-group label="Desde" slot-scope="{ valid, errors }">
                        <b-form-input
                          class="form-control"
                          type="number"
                          v-model="currentConfig.rooms_from"
                          :disabled="!canEditConfig"
                          @keypress="onlyNumberWithDecimal"
                          :state="errors[0] ? false : valid ? true : false"
                        />
                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>

                  <b-col md="" sm="">
                    <ValidationProvider name="Hasta" rules="required|min_value:1|max_value:99">
                      <b-form-group label="Hasta" slot-scope="{ valid, errors }">
                        <b-form-input
                          class="form-control"
                          type="number"
                          v-model="currentConfig.rooms_to"
                          :disabled="!canEditConfig"
                          @keypress="onlyNumberWithDecimal"
                          :state="errors[0] ? false : valid ? true : false"
                        />
                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>

                  <b-col md="4" sm="">
                    <ValidationProvider name="Monto" rules="required|max:11">
                      <b-form-group label="Monto" slot-scope="{ valid, errors }">
                        <b-form-input
                          class="form-control"
                          type="number"
                          step="0.01"
                          @keypress="onlyNumberWithDecimal"
                          v-model="currentConfig.amount"
                          :disabled="!canEditConfig"
                          :state="errors[0] ? false : valid ? true : false"
                        />
                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>

                  <b-col md="4">
                    <validation-provider name="Divisa" rules="required">
                      <b-form-group slot-scope="{ valid, errors }" label="Divisa">
                        <b-form-select
                          :state="errors[0] ? false : valid ? true : null"
                          v-model="currentConfig.currency"
                          :disabled="!canEditConfig"
                          :clearable="false"
                        >
                          <option :value="null" disabledd>Seleccione una opción</option>
                          <option v-for="cur in currencies" :key="cur.id" :value="cur.currencyid">
                            {{ cur.code }}
                          </option>
                        </b-form-select>
                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                </b-row>
              </b-col>

              <b-col align-self="end" md="1" class="mt-1">
                <ValidationProvider rules="" name="status">
                  <b-form-group>
                    <label>Status</label>
                    <b-form-checkbox
                      v-model="currentConfig.status"
                      :disabled="!can('fivesclub_profit_allow_monetization_configuration_edition')"
                      switch
                    />
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>

            <hr>

            <b-button
              v-if="can('fivesclub_profit_allow_monetization_configuration_edition')"
              variant="primary"
              type="submit"
              class="float-right"
              :disabledd="invalid || isLoadingMonetizingConfig || isUpdatingMonetizingConfig"
            > Guardar
            </b-button>
          </b-form>
        </validation-observer>
      </div>
      <div v-else-if="!isLoadingMonetizingConfig && !isUpdatingMonetizingConfig">
        <b-alert variant="danger" show >
          <div class="alert-body"> No se han encontrado registros válidos </div>
        </b-alert>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import { validYears, isANumber } from "@/helpers/helpers";
import { mapActions, mapState, mapMutations } from "vuex";
import { utils } from "@/modules/fivesClub/mixins/utils"
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select';
import { acl } from "@/modules/auth/mixins/acl"

export default {
  mixins: [utils, acl],
  directives: { Ripple, },
  components: {
    vSelect
  },
  async mounted() {
    await this.init()
  },
  data() {
    return {
      idConfig: this.$route.params.id,
      isLoadingMonetizingConfig:false,
      isUpdatingMonetizingConfig:false,
      canEditForm: true,

      currentConfig: null
    }
  },
  computed: {
    ...mapState('fivesClubProfit',['catalogPerformanceConcepts', 'roomsSubTypes']),
    ...mapState("fivesClubCatalogs", ['benefitOptions']),
    ...mapState("start", ["hotels", "currencies"]),
    listyears(){
      return validYears()
    },
    roomsConfig(){
      return this.roomsSubTypes
    },
    canEditConfig(){
      return this.can("fivesclub_profit_allow_monetization_configuration_edition") && this.canEditForm
    }
  },
  methods: {
    ...mapActions('start',['getHotels', 'fetchCurrencies']),
    ...mapActions('fivesClubProfit', ['getInitialContentForBenefitsMonetizationConfig', 'fetchBenefitsMonetizationConfig', 'updateBenefitsMonetizationConfig','fetchRoomSubtypes']),
    ...mapMutations('start',['setHotels', 'setCurrencies']),
    ...mapMutations('fivesClubProfit',['setPerformanceConcepts', 'setCatalogPerformanceConcepts']),

    async init() {
			if(isANumber(this.idConfig)){
        this.isLoadingMonetizingConfig = true

        await this.getInitialContentForBenefitsMonetizationConfig({
          benefits: !this.benefitOptions.length > 0,
          hotels: !this.hotels.length > 0,
          roomsTypes: !this.roomsSubTypes.length > 0,
          currencies: !this.currencies.length > 0
        })

        const config = await this.fetchBenefitsMonetizationConfig({configId: this.idConfig})
        if (!config.length) {
          const {id, hotel, benefit, currency, rooms_from, rooms_to, amount, status, roomsubtype } = config
          this.canEditForm = structuredClone(status)
          this.currentConfig = {
            id,
            hotel,
            benefit,
            currency,
            rooms_from,
            rooms_to,
            amount,
            status,
            roomsCategory: this.roomsSubTypes.filter(room => roomsubtype.includes(room.id))
          }

        } else this.currentConfig = null
        this.isLoadingMonetizingConfig = false
      }
    },


    async updateConfiguration() {
      const isConfirmed = await this.confirmSaving()
      if (isConfirmed) {
        this.isUpdatingMonetizingConfig = true
        const payload = {
          ...this.currentConfig,
        }
        payload.roomsCategory = this.currentConfig.roomsCategory.map(roomsCat => roomsCat.id)
        payload.amount = parseFloat(payload.amount).toFixed(2)

        const response = await this.updateBenefitsMonetizationConfig(payload)

        if (response) {
          this.isUpdatingMonetizingConfig = false
          this.goToHome({
            reload: true,
            hotelId: payload.hotel,
            benefitId: payload.benefit,
          })
        }
        this.isUpdatingMonetizingConfig = false
      }
    },
    resetRoomsCategory(){
      this.currentConfig.roomsCategory = []
    },
    async confirmSaving() {
      const { isConfirmed } = await this.$swal.fire({
        title: "¿Deseas guardar la información?",
        text: "Favor de confirmar",
        showDenyButton: true,
        confirmButtonText: "Sí, guardar",
        denyButtonText: "Cancelar",
        customClass: {
          confirmButton: 'swal-button-yes',
          denyButton: 'swal-button-cancel',
        },
      })
      return isConfirmed
    },
    goToHome(params){
      this.$router.push({ name: 'monetizing-config', params })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>